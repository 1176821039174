import React, { useEffect, useState } from "react";
import { setUserFormData } from "../../../store/actions/form";
import { getFreeSlots } from "../../../store/actions/freeSlots";
import { findFormErrors } from "../../../validation/form-validation";
import { setStep } from "../../../store/actions/step";
import { useDispatch, useSelector } from "react-redux";
import NextCategoryButton from "../NextButton/NextCategoryButton";
import ChooseSlot from "../ChooseSlot/ChooseSlot";
import { Row, Col } from "react-bootstrap";
import "./Step4.scss";

const Step4 = () => {
  const prevUserData = useSelector((state) => state?.userData?.step4);

  const dispatch = useDispatch();
  const [errors, setErrors] = useState({});
  const [userOrder, setUserOrder] = useState(
    prevUserData ? prevUserData : { date: "", time: "", index: "" }
  );

  const saveForm = (step) => {
    let errorsValidation = findFormErrors(userOrder);
    setErrors(errorsValidation);

    if (Object.keys(errorsValidation).length === 0) {
      dispatch(setUserFormData("step4", { ...userOrder }));
      dispatch(setStep(step + 1));
    }
  };

  useEffect(() => {
    var date = new Date();
    date.setDate(date.getDate());
    let firstDate = date.toISOString().split("T")[0];
    date.setDate(date.getDate() + 31);
    let lastDate = date.toISOString().split("T")[0];

    let params = {
      first: firstDate,
      last: lastDate,
    };

    dispatch(getFreeSlots(params));
  }, [dispatch]);

  return (
    <div className='Step4'>
      <Row className='w-100 h-100 mx-0'>
        {/* <Col lg={12} xl={12} className='pt-4'>
          <h4>ODABERITE DATUM I VREME</h4>
        </Col> */}

        <Col lg={12} xl={12} className='pt-2 h-100 px-0'>
          <div className='Widget'>
            <div className={"widgetWrapper"}>
              <div className='BookingHeader'>
                <div
                  style={{
                    alignItems: "flex-end",
                    display: "grid",
                    height: "100%",
                  }}>
                  <p>ODABERITE DATUM I VREME</p>
                  <p style={{ color: "red", margin: "0", fontSize: "20px" }}>
                    {errors.date}
                  </p>
                </div>
              </div>
              <ChooseSlot userOrder={userOrder} setUserOrder={setUserOrder} />
            </div>
          </div>
          <Col lg={6} xl={6} className='pt-4 mx-auto'>
            <div
              style={{
                alignItems: "center",
                justifyContent: "center",
              }}>
              <NextCategoryButton saveForm={saveForm} />
            </div>
          </Col>
        </Col>
      </Row>
    </div>
  );
};

export default Step4;
