import React, { useState } from "react";
import { deleteUserFormData } from "../../../store/actions/form";
import { useDispatch, useSelector } from "react-redux";
import NextCategoryButton from "../NextButton/NextCategoryButton";
import { Row, Col, Image } from "react-bootstrap";
import { ImChrome } from "react-icons/im";
import { SiGmail } from "react-icons/si";
import { MdPhoneInTalk } from "react-icons/md";
import OdnesiFormPlaceholderImg from "../../../assets/images/logo-normal-svg.png";
import "./Step7.scss";

const Step7 = () => {
  const appoitmentSuccess = useSelector(
    (state) => state?.userData?.appoitmentSuccess
  );
  const [serviceNumber, setServiceNumber] = useState("xxxxxxxxx");
  const dispatch = useDispatch();

  const saveForm = () => {
    dispatch(deleteUserFormData());
  };

  const showNumber = () => {
    setServiceNumber("0638793499");
  };

  return (
    <div className='Step7'>
      <Row>
        <Col xl={7} lg={7}>
          <Col xl={12} lg={12}>
            {" "}
            <Image src={OdnesiFormPlaceholderImg} />
          </Col>
          <Col xl={6} lg={6} md={8} sm={12} xs={12} className='message mt-4'>
            <p>{appoitmentSuccess && appoitmentSuccess}</p>
          </Col>
          <Col xl={6} lg={6} md={8} sm={12} xs={12} className='message'>
            <ImChrome />
            <a href='https://www.odnesi.co' className='ml-3'>
              www.odnesi.co
            </a>
          </Col>
          <Col xl={6} lg={6} md={8} sm={12} xs={12} className='mt-3 message'>
            <SiGmail />
            <a href='mailto:servisodnesi@gmail.com' className='ml-3'>
              servisodnesi@gmail.com
            </a>
          </Col>
          <Col xl={6} lg={6} md={8} sm={12} xs={12} className='mt-3 message'>
            <MdPhoneInTalk />
            <p className='call_center ml-3' onClick={showNumber}>
              {serviceNumber}
            </p>
          </Col>
          <Col
            xl={6}
            lg={6}
            md={8}
            sm={12}
            xs={12}
            className='mx-auto mt-5 Submit_button_layer'>
            <NextCategoryButton saveForm={saveForm} />
          </Col>
        </Col>
      </Row>
    </div>
  );
};

export default Step7;
