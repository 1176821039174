import axios from "axios";

let baseURL = "";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

export { baseURL };

export default axiosInstance;
