import React, { useRef, useEffect } from "react";
import "./LocationsList.scss";
var convert = require("cyrillic-to-latin");
const LocationsList = ({
  isAddressShown,
  locations,
  saveLocation,
  setIsAdressShown,
  setIsOpened,
  getLocationDetails,
}) => {
  const wrapperRef = useRef();

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside, false);

    return () =>
      document.removeEventListener("mousedown", handleClickOutside, false);
  }, []);

  const handleClickOutside = (event) => {
    event.stopPropagation();

    // console.log(wrapperRef.current);
    if (wrapperRef && !wrapperRef.current.contains(event.target)) {
      if (setIsAdressShown) {
        setIsAdressShown(false);
        setIsOpened(false);
      }
    }
  };

  return (
    <div className='LocationsList'>
      <div
        ref={wrapperRef}
        className={`list-group ${!isAddressShown ? "hidden" : "shown"}`}
        style={{
          position: "absolute",
          zIndex: "999",
          width: "100%",
          left: "0",
          overflowY: "auto",
        }}>
        {locations &&
          locations.map((location) => {
            return (
              <a
                key={location.place_id}
                // href='#'
                className='list-group-item list-group-item-action flex-column align-items-start'
                onClick={(e) => {
                  if (getLocationDetails) {
                    // call when this component called from search location
                    let googleDiv = document.querySelector(".googlePlace");
                    let geometry = {
                      location: {
                        lat: "",
                        lng: "",
                      },
                    };

                    getLocationDetails(location.place_id, googleDiv)
                      .then((res) => {
                        let locationLat = res.geometry.location.lat();
                        let locationLng = res.geometry.location.lng();
                        const finallCords = {
                          ...geometry,
                          location: {
                            lat: locationLat,
                            lng: locationLng,
                          },
                        };

                        let newObj = {
                          ...location,
                          geometry: finallCords,
                          address_components: res.address_components,
                        };

                        saveLocation(e, newObj);
                      })
                      .catch((error) => {
                        console.log("error ", error);
                      });
                  } else {
                    // call when this component called from auto detect location
                    saveLocation(e, location);
                    if (setIsAdressShown) {
                      setIsAdressShown(false);
                      setIsOpened(false);
                    }
                  }
                }}>
                <div className='d-flex w-100 h-100 justify-content-center align-items-center'>
                  <h5 className='mb-1'>
                    {location.formatted_address
                      ? convert(location.formatted_address)
                      : convert(location.description)}
                  </h5>
                </div>
              </a>
            );
          })}
      </div>
      <div className='googlePlace'></div>
    </div>
  );
};

export default LocationsList;
