import axios from "axios";
import * as actionTypes from "./actionTypes";
import { Loader } from "@googlemaps/js-api-loader";

const loader = new Loader({
  apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
  libraries: ["places"],
});

export const setLocations = (payload) => {
  return {
    type: actionTypes.SET_LOCATIONS,
    payload: payload,
  };
};

export const removeLocations = () => {
  return {
    type: actionTypes.REMOVE_LOCATIONS,
  };
};

export const setDetectedLocations = (payload) => {
  return {
    type: actionTypes.SET_DETECT_LOCATION,
    payload: payload,
  };
};

export const detectionLocation = (data) => {
  // auto detect location
  return (dispatch) => {
    axios
      .get(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${data.lat},${data.lon}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`
      )
      .then((res) => {
        console.log("data ", res.data.results);

        let filteredList = res.data.results
          .filter((item1) =>
            item1.address_components.some(
              (item2) =>
                (item2.types.includes("locality" || "political") &&
                  item2.long_name === "Nis") ||
                item2.long_name === "Niš" ||
                item2.long_name === "Pirot"
            )
          )
          .filter((item1) =>
            item1.address_components.some((item2) =>
              item2.types.includes("route")
            )
          )
          .filter(
            (thing, index, self) =>
              index ===
              self.findIndex(
                (t) => t.formatted_address === thing.formatted_address
              )
          );

        dispatch(setDetectedLocations(filteredList));
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const getLocation = (data) => {
  // detect location on search
  return (dispatch) => {
    loader
      .load()
      .then((google) => {
        const AutocompleteService =
          new google.maps.places.AutocompleteService();

        AutocompleteService.getPlacePredictions(
          {
            input: `${data.city},${data.address}`,
            types: ["address"],
            componentRestrictions: { country: "rs" },
          },
          (res) => {
            dispatch(setLocations(res));
          },
          (err) => {
            console.log("error ", err);
          }
        );
      })
      .catch((error) => {
        console.log("error ", error);
      });
  };
};

export const getLocationDetails = (place_id, googleDiv) => {
  // detect location details after click on specific address from address sugestions
  return new Promise((resolve, reject) => {
    loader
      .load()
      .then((google) => {
        const AutocompleteService = new google.maps.places.PlacesService(
          googleDiv
        );
        AutocompleteService.getDetails(
          {
            placeId: place_id,
            fields: ["geometry.location", "address_components"],
          },
          (res) => {
            resolve(res);
            // console.log("response", res);
          },
          (err) => {
            reject(err);
            console.log("error ", err);
          }
        );
      })
      .catch((error) => {
        console.log("err ", error);
      });
  });
};
