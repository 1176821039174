import * as actionTypes from "./actionTypes";

export const setUserFormData = (step, payload) => {
  if (step === "step1") {
    return {
      type: actionTypes.SET_STEP_1,
      payload: payload,
    };
  } else if (step === "step2") {
    return {
      type: actionTypes.SET_STEP_2,
      payload: payload,
    };
  } else if (step === "step3") {
    return {
      type: actionTypes.SET_STEP_3,
      payload: payload,
    };
  } else if (step === "step4") {
    return {
      type: actionTypes.SET_STEP_4,
      payload: payload,
    };
  } else if (step === "step5") {
    return {
      type: actionTypes.SET_STEP_5,
      payload: payload,
    };
  }
};

export const deleteUserFormData = (payload) => {
  return {
    type: actionTypes.REMOVE_USER_DATA,
    payload: payload,
  };
};

export const setAppoitmentErrors = (payload) => {
  return {
    type: actionTypes.SET_APPOITMENT_ERROR,
    payload: payload,
  };
};

export const setAppoitmentSuccess = (payload) => {
  return {
    type: actionTypes.SET_APPOITMENT_SUCCESS,
    payload: payload,
  };
};

export const clearAppoitmentsErrors = (payload) => {
  return {
    type: actionTypes.CLEAR_APPOITMENT_ERRORS,
    payload: payload,
  };
};
