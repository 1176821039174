import axios from "../../Utility/axios";
import * as actionTypes from "./actionTypes";

export const getFreeSlots = (date) => {
  return (dispatch) => {
    axios
      .get("/free-slots", {
        params: {
          date: date.first,
          date_to: date.last,
        },
      })
      .then((res) => {
        // console.log(res);
        dispatch(setFreeSlots(res.data.data));
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const setFreeSlots = (payload) => {
  return {
    type: actionTypes.SET_FREE_SLOTS,
    payload,
  };
};
