export const SET_STEP = "SET_STEP";
export const SET_LOCATIONS = "SET_LOCATIONS";
export const REMOVE_LOCATIONS = "REMOVE_LOCATIONS";
export const SET_DETECT_LOCATION = "SET_DETECT_LOCATION";
export const SET_USER_DATA = "SET_USER_DATA";
export const REMOVE_USER_DATA = "REMOVE_USER_DATA";
export const SET_APPOITMENT_ERROR = "SET_APPOITMENT_ERROR";
export const CLEAR_APPOITMENT_ERRORS = "CLEAR_APPOITMENT_ERRORS";
export const SET_APPOITMENT_SUCCESS = "SET_APPOITMENT_SUCCESS";
export const SAVE_SERVICES = "SAVE_SERVICES";
export const SAVE_SELECTED_SERVICES = "SAVE_SELECTED_SERVICES";
export const SET_FREE_SLOTS = "SET_FREE_SLOTS";
export const SET_STEP_1 = "SET_STEP_1";
export const SET_STEP_2 = "SET_STEP_2";
export const SET_STEP_3 = "SET_STEP_3";
export const SET_STEP_4 = "SET_STEP_4";
export const SET_STEP_5 = "SET_STEP_5";
