import * as actionTypes from "../actions/actionTypes";

let initialState = {
  services: null,
  selectedServices: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SAVE_SERVICES:
      return {
        ...state,
        services: action.payload,
      };
    case actionTypes.SAVE_SELECTED_SERVICES:
      return {
        ...state,
        selectedServices: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
