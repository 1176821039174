import React, { useState, useEffect } from "react";
import {
  getLocation,
  getLocationDetails,
  removeLocations,
} from "../../../store/actions/location";
import { setUserFormData } from "../../../store/actions/form";
import { setStep } from "../../../store/actions/step";
import { findFormErrors } from "../../../validation/form-validation";
import { useDispatch, useSelector } from "react-redux";
import SearchInput, { createFilter } from "react-search-input";
import InputComp from "../InputComp/InputComp";
import NextCategoryButton from "../NextButton/NextCategoryButton";
import LocationPicker from "../LocationPicker/LocationPicker";
import LocationList from "../LocationsList/LocationsList";
import { Row, Col, Form } from "react-bootstrap";

import "./Step3.scss";
var convert = require("cyrillic-to-latin");

let changeTimeout = null;

const Step3 = () => {
  const locations = useSelector((state) => state.locations.locations);

  const prevUserData = useSelector((state) => state?.userData?.step3);
  const [isOpened, setIsOpened] = useState(false);

  const dispatch = useDispatch();

  const [errors, setErrors] = useState({});

  const [userOrder, setUserOrder] = useState(
    prevUserData
      ? prevUserData
      : {
          city: "Niš",
          address: "",
          // township: "",
          floor: "",
          latitude: "",
          longitude: "",
          house_number: "",
          has_elevator: false,
          location_type: "house",
          appartment_number: "",
        }
  );
  const [isAddressShown, setIsAdressShown] = useState(false);

  // set street to form informations
  const handleStreet = (street) => {
    const address = street;
    setUserOrder((prevState) => {
      return { ...prevState, address };
    });
  };

  const saveForm = (step) => {
    let errorsValidation = findFormErrors(userOrder);
    setErrors(errorsValidation);

    if (Object.keys(errorsValidation).length === 0) {
      dispatch(setUserFormData("step3", { ...userOrder }));
      dispatch(setStep(step + 1));
    }
  };

  //set other form informations
  const handleOtherInfo = (event) => {
    let input = userOrder;
    input[event.target.name] = convert(event?.target?.value);

    setUserOrder((prevState) => {
      return { ...prevState, ...input };
    });
  };

  const saveLocation = (e, location) => {
    e.preventDefault();
    setIsAdressShown(false);

    let autoDetectStreet = location.address_components.find((item1) =>
      item1.types.includes("route")
    );

    setUserOrder((prevState) => {
      return {
        ...prevState,
        latitude: location.geometry.location.lat,
        longitude: location.geometry.location.lng,
        address: autoDetectStreet.long_name,
        // city: autoDetectCity.long_name,
      };
    });
  };

  useEffect(() => {
    if (changeTimeout != null) clearTimeout(changeTimeout);

    if (userOrder.address.length > 2) {
      changeTimeout = setTimeout(() => {
        dispatch(getLocation(userOrder));
      }, 500);
    } else if (userOrder.address.length === 0 && locations?.length > 0) {
      dispatch(removeLocations());
    }
  }, [userOrder.city, userOrder.address]);

  return (
    <div className='Step3'>
      <Row className='w-100 mx-0 Step3__container'>
        <div className='title_line'></div>
        <Col lg={12} xl={12} className='pt-2'>
          <h4>ODABERITE LOKACIJU</h4>
        </Col>

        <Col lg={12} xl={12} className='mx-auto'>
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={10}
            xl={6}
            className='mx-auto pt-1 px-0'
            style={{ display: "flex", justifyContent: "center" }}>
            <Form className='pt-2' autoComplete='off'>
              {/* <InputComp
                type='text'
                name='city'
                label='Odaberite grad:'
                value={userOrder.city}
                handleInfo={handleOtherInfo}
                error={errors.city}
              /> */}
              {/* <Form.Group as={Col} controlId='formGridState'> */}
              <Form.Group>
                <Form.Label className='float-left'>Odaberite grad:</Form.Label>
                <Form.Control
                  as='select'
                  type='text'
                  name='city'
                  value={userOrder.city}
                  onChange={(e) => handleOtherInfo(e)}>
                  <option value='Niš'>Niš</option>
                  <option value='Pirot'>Pirot</option>
                </Form.Control>
                <small id='emailHelp' className='form-text text-muted d-flex'>
                  {errors.city}
                </small>
              </Form.Group>

              <SearchInput
                className={`search-input ${
                  errors.address
                    ? " border border-danger"
                    : errors.latitude
                    ? "border border-danger"
                    : ""
                }`}
                name='street'
                placeholder='Naziv ulice'
                value={userOrder.address}
                onChange={handleStreet}
                onClick={() => setIsAdressShown(true)}
                // onFocus={() => setIsAdressShown(true)}
              />
              <small id='emailHelp' className='form-text text-muted d-flex'>
                {errors.address ? errors.address : errors.latitude}
              </small>
              <LocationList
                isAddressShown={isAddressShown}
                locations={locations}
                saveLocation={saveLocation}
                setIsAdressShown={setIsAdressShown}
                setIsOpened={setIsOpened}
                getLocationDetails={getLocationDetails}
              />

              {/* <InputComp
                type='text'
                name='township'
                label='Opstina:'
                value={userOrder.township}
                handleInfo={handleOtherInfo}
              /> */}
              <Row style={{ justifyContent: "left" }}>
                <Col xl={5} lg={5} md={5} sm={5} xs={5} className='text-right'>
                  Kuca
                </Col>

                <Col xl={2} lg={2} md={2} sm={2} xs={2}>
                  <Form.Group controlId='formBasicEmail'>
                    <Form.Check
                      type='switch'
                      id='custom-switch1'
                      size='sm'
                      checked={
                        userOrder.location_type === "house" ? false : true
                      }
                      onChange={() =>
                        setUserOrder((prevState) => {
                          return {
                            ...prevState,
                            location_type:
                              prevState.location_type === "house"
                                ? "building"
                                : "house",
                          };
                        })
                      }
                    />
                  </Form.Group>
                </Col>

                <Col xl={5} lg={5} md={5} sm={5} xs={5} className='text-left'>
                  Zgrada
                </Col>
                <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                  <InputComp
                    type='text'
                    name='house_number'
                    label={
                      userOrder.location_type === "house"
                        ? "Broj kuće:"
                        : "Broj zgrade:"
                    }
                    value={userOrder.house_number}
                    handleInfo={handleOtherInfo}
                    error={errors.house_number}
                    // placeHolder='Opciono 4/1 ili BB'
                  />
                </Col>

                {userOrder.location_type === "building" && (
                  <>
                    <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                      <InputComp
                        type='text'
                        name='appartment_number'
                        label='Broj stana:'
                        value={userOrder.appartment_number}
                        handleInfo={handleOtherInfo}
                        // error={errors.appartment_number}
                        // placeHolder='Broj stana'
                      />
                    </Col>
                    <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                      <InputComp
                        type='text'
                        name='floor'
                        label='Sprat br:'
                        // error={errors.floor}
                        value={userOrder.floor}
                        handleInfo={handleOtherInfo}
                      />
                    </Col>
                    <Col
                      xl={6}
                      lg={6}
                      md={6}
                      sm={6}
                      xs={6}
                      style={{
                        display: "flex",
                        justifyContent: "left",
                        alignItems: "center",
                      }}>
                      <Form.Group controlId='formBasicEmail2'>
                        <Form.Check
                          type='switch'
                          id='custom-switch2'
                          label='Lift'
                          size='sm'
                          checked={userOrder.has_elevator}
                          style={{ marginTop: "50%" }}
                          onChange={() =>
                            setUserOrder((prevState) => {
                              return {
                                ...prevState,
                                has_elevator: !prevState.has_elevator,
                              };
                            })
                          }
                        />
                      </Form.Group>
                    </Col>
                  </>
                )}
              </Row>
            </Form>
          </Col>
          <Col
            xs={12}
            sm={12}
            md={8}
            lg={8}
            xl={8}
            className='mx-auto pt-2 auto-detect-location'
            style={{ display: "flex", justifyContent: "center" }}>
            <LocationPicker saveLocation={saveLocation} />
          </Col>
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={10}
            xl={6}
            className='mx-auto pt-3'
            style={{ display: "flex", justifyContent: "center", padding: "0" }}>
            <NextCategoryButton saveForm={saveForm} />
          </Col>
        </Col>
      </Row>
    </div>
  );
};

export default Step3;
