export const findFormErrors = (userOrder) => {
  const newErrors = {};
  // var numberPattern = new RegExp(/^[0-9\b]+$/);

  var numberPattern = new RegExp("^(?=.*?[1-9])[0-9()+-]+$");

  Object.entries(userOrder).forEach(([key, value]) => {
    if (
      // key !== "township" &&
      key !== "index" &&
      key !== "comment" &&
      key !== "has_elevator"
    ) {
      if (value || value !== "") {
        // if (key === "location_type" && value === "building") {
        //   Object.entries(userOrder).forEach(([key, value]) => {
        //     if (key === "appartment_number" && value.length === 0) {
        //       newErrors[key] = "Broj apartmana mora biti upisan";
        //     } else if (key === "floor" && value.length === 0) {
        //       newErrors[key] = "Broj sprata mora biti upisan";
        //     }
        //   });
        // } else
        if (key === "phoneNumber") {
          if (value.length < 8)
            newErrors[key] = "Broj telefona je previse kratak";
          else if (value.length > 14)
            newErrors[key] = "Broj telefona je predugacak";
          else if (!numberPattern.test(value))
            newErrors[key] = "Unesite samo broj";
        }
      } else {
        if (key === "date" || key === "time") {
          newErrors[key] = "Datum mora biti izabran";
        } else if (key === "latitude" || key === "longitude") {
          newErrors[key] = "Adresa mora biti izabrana iz predloga";
        } else if (key === "house_number") {
          newErrors[key] = "Broj kuće mora biti upisan";
        } else if (key === "phoneNumber") {
          if (value.length === 0)
            newErrors[key] = "Broj telefona mora biti upisan";
        } else if (key === "name" && value.length === 0) {
          newErrors[key] = "Ime mora biti upisano";
        } else if (key === "city" && value.length === 0) {
          newErrors[key] = "Grad mora biti izabran";
        }
      }
    }
  });

  return newErrors;
};
