import React, { useState } from "react";
import ScheduledAppointments from "../OrderForm/ScheduledAppointments/ScheduledAppointments";
import { Button, Image } from "react-bootstrap";
import FooterLogo from "../../assets/images/SP_Logo_blok_cir.jpg";
import { FaShuttleVan } from "react-icons/fa";
import "./Footer.scss";

const Footer = () => {
  const [modalShow, setIsModalShow] = useState(false);
  const [numberOfAppointments, setNumberOfAppointments] = useState(null);

  return (
    <div className='Footer'>
      <p className='Footer__title'> All rights reserved - odnesi.co</p>
      <Button
        variant='secondary'
        onClick={() => setIsModalShow(true)}
        className='shadow-none rounded-0'>
        {numberOfAppointments}
        <FaShuttleVan className='ml-2' />
      </Button>
      {/* {modalShow && ( */}
      <ScheduledAppointments
        show={modalShow}
        setIsModalShow={setIsModalShow}
        setNumberOfAppointments={setNumberOfAppointments}
      />
      {/* )} */}
      <Image src={FooterLogo} />
    </div>
  );
};

export default Footer;
