import * as actionTypes from "../actions/actionTypes";

let initialState = 1;

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_STEP:
      return (initialState = action.payload);
    default:
      return state;
  }
};

export default reducer;
