import React from "react";
import { Image } from "react-bootstrap";
import OdnesiFormPlaceholderImg from "../../../assets/images/logo-nobackground-1000.png";
import SwissPlaceholderImg from "../../../assets/images/swiss-pro.jpg";
import "./OrderPlaceHolder.scss";

const OrderPlaceHolder = () => {
  return (
    <div className='OrderPlaceHolder'>
      <div className='OrderPlaceHolder__first'>
        <Image src={OdnesiFormPlaceholderImg} />
      </div>
      <div className='OrderPlaceHolder__second'>
        <Image src={SwissPlaceholderImg} />
      </div>
      <div className='vl'></div>
    </div>
  );
};

export default OrderPlaceHolder;
