import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setStep } from "../../../store/actions/step";
import { Image } from "react-bootstrap";
import VectorArrow from "../../../assets/images/BackArrow.png";
import "./BackButton.scss";

const BackButton = () => {
  const dispatch = useDispatch();
  const step = useSelector((state) => state.step);

  const prevStep = () => {
    dispatch(setStep(step - 1));
  };

  return (
    <>
      {step > 1 && step !== 7 && (
        <div className='BackButton' onClick={prevStep}>
          <div className='section2'>
            <Image src={VectorArrow} />
          </div>
          <div className='section1'>Korak nazad</div>
        </div>
      )}
    </>
  );
};

export default BackButton;
