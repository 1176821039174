import * as actionTypes from "../actions/actionTypes";

let initialState = {
  step1: null,
  step2: null,
  step3: null,
  step4: null,
  appoitmentErrors: null,
  appoitmentSuccess: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_STEP_1:
      return {
        ...state,
        step1: action.payload,
      };
    case actionTypes.SET_STEP_2:
      return {
        ...state,
        step2: action.payload,
      };
    case actionTypes.SET_STEP_3:
      return {
        ...state,
        step3: action.payload,
      };
    case actionTypes.SET_STEP_4:
      return {
        ...state,
        step4: action.payload,
      };
    case actionTypes.SET_STEP_5:
      return {
        ...state,
        step5: action.payload,
      };
    case actionTypes.SET_APPOITMENT_ERROR:
      return {
        ...state,
        appoitmentErrors: action.payload,
      };
    case actionTypes.SET_APPOITMENT_SUCCESS:
      return {
        ...state,
        appoitmentSuccess: action.payload,
      };
    case actionTypes.CLEAR_APPOITMENT_ERRORS:
      return {
        ...state,
        appoitmentErrors: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
