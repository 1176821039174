import React from "react";
import { useSelector } from "react-redux";
import { Image } from "react-bootstrap";
import VectorArrow from "../../../assets/images/VectorArrow.png";
import "./NextCategoryButton.scss";

const NextCategoryButton = ({ saveForm }) => {
  const step = useSelector((state) => state.step);

  const buttonSelect = () => {
    if (step !== 6 && step !== 7) {
      return (
        <div
          className={`NextCategoryButton ${step === 4 && "large"}`}
          onClick={() => saveForm(step)}>
          <div className='section1'>
            {step === 2
              ? "Dalje"
              : step < 5 && step !== 2
              ? "Sledeci korak"
              : "Sacuvaj podatke"}
            {/* {step < 4 ? "Sledeci korak" : "Sacuvaj podatke"} */}
          </div>
          <div className='section2'>
            <Image src={VectorArrow} />
          </div>
        </div>
      );
    } else if (step === 6) {
      return (
        <div className='Submit_button' onClick={() => saveForm(step)}>
          Potvrdi
        </div>
      );
    } else {
      return (
        <div className='Submit_button' onClick={() => saveForm()}>
          POCETNA STRANA
        </div>
      );
    }
  };
  return <> {buttonSelect()} </>;
};

export default NextCategoryButton;
