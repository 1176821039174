import React, { useState, useEffect } from "react";
import { setUserFormData } from "../../../store/actions/form";
import {
  getServices,
  getServicesResponse,
} from "../../../store/actions/services";
import { setStep } from "../../../store/actions/step";
import { useDispatch, useSelector } from "react-redux";
import NextCategoryButton from "../NextButton/NextCategoryButton";
import { Row, Col, Image } from "react-bootstrap";
import VectorImg from "../../../assets/images/Vector.png";
import "./Step1.scss";

const Step1 = () => {
  const activeCategory = useSelector((state) => state?.userData?.step1);
  const services = useSelector((state) => state.services);

  const [activeService, setActiveService] = useState(
    activeCategory ? activeCategory : 1
  );

  const dispatch = useDispatch();

  const saveForm = (step) => {
    dispatch(setUserFormData("step1", activeService));
    dispatch(getServicesResponse(activeService));
    dispatch(setStep(step + 1));
  };

  useEffect(() => {
    dispatch(getServices());
  }, [dispatch]);

  const allServices =
    services.services &&
    services.services.map((service) => {
      return (
        <div
          key={service.id}
          className={`category-button ${
            activeService === service.id && " active"
          }`}
          onClick={() => setActiveService(service.id)}>
          {activeService === service.id && <Image src={VectorImg} />}
          <div className='category-button__text'>
            <p>{service.name}</p>
          </div>
        </div>
      );
    });

  return (
    <div className='Step1'>
      <Row className='w-100'>
        <Col className='chooseCategory'>
          <Row className='w-100'>
            <div className='title_line'></div>
            <Col lg={12} xl={12} className='pt-2 step_title'>
              <p>ODABERITE KATEGORIJU</p>
            </Col>
            <Col
              sm={12}
              md={12}
              lg={10}
              xl={6}
              className='mx-auto'
              style={{ display: "flex", justifyContent: "center" }}>
              <div className='buttons-container'>{allServices}</div>
            </Col>
            <Col lg={12} xl={12} md={12} sm={12}></Col>
            <Col
              sm={12}
              md={12}
              lg={10}
              xl={5}
              className='mx-auto pt-5'
              style={{ display: "flex", justifyContent: "center" }}>
              <NextCategoryButton saveForm={saveForm} />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default Step1;
