import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { createAppointment } from "../../../store/actions/appointment";
import NextCategoryButton from "../NextButton/NextCategoryButton";
import { Row, Col } from "react-bootstrap";
import Swal from "sweetalert2";
import "./Step6.scss";

const Step6 = () => {
  const userData = useSelector((state) => state?.userData);
  const appoitmentErrors = useSelector(
    (state) => state?.userData?.appoitmentErrors
  );

  const finallOrder = useSelector((state) => state.userData);
  const dispatch = useDispatch();

  const sendOrder = () => {
    let finallObject = {};
    let location = {};

    finallOrder &&
      Object.keys(finallOrder).map((day) => {
        if (day === "step1") {
          finallObject = { ...finallObject, service_id: finallOrder[day] };
        } else if (day === "step2") {
          finallObject = {
            ...finallObject,
            comment: finallOrder[day].comment,
          };
        } else if (day === "step3") {
          for (var key in finallOrder[day]) {
            if (finallOrder[day].hasOwnProperty(key)) {
              location = { ...location, [key]: finallOrder[day][key] };
            }
          }

          finallObject = { ...finallObject, location };
        } else if (day === "step4") {
          finallObject = {
            ...finallObject,
            date: finallOrder[day].date,
            time: finallOrder[day].time,
          };
        } else if (day === "step5") {
          finallObject = {
            ...finallObject,
            name: finallOrder[day].name,
            phone: finallOrder[day].phoneNumber,
          };
        }
      });

    dispatch(createAppointment(finallObject));
  };

  useEffect(() => {
    if (appoitmentErrors !== null) {
      let errors = "";

      appoitmentErrors &&
        Object.keys(appoitmentErrors).map((value, key) =>
          appoitmentErrors[value].map(
            (epg) => (errors += key + 1 + " " + epg + "<br />")
          )
        );

      Swal.fire({
        icon: "error",
        title: errors,
        confirmButtonText: "Izadji",
        confirmButtonColor: "red",
        footer: '<a href="">Zasto se desila greska?</a>',
      });

      // Swal.fire({
      //   icon: "error",
      //   title: "Greska...",
      //   html: errors,
      //   confirmButtonText: "Izadji",
      //   confirmButtonColor: "red",
      //   footer: '<a href="">Zasto se desila greska?</a>',
      // });
    }
  }, [appoitmentErrors]);

  const styledValues = {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#f5f5f5",
    justifyContent: "center",
    height: "3rem",
  };

  return (
    <div className='Step6'>
      <Row className='w-100 Step6__container mx-0 h-100 text-left'>
        <Col xs={12} sm={12} md={12} lg={10} xl={10} className='title'>
          <Row>
            <Col lg={10} xl={10} md={10} sm={10} xs={10}>
              <div className='title_line'></div>
              <div className='title_text'>
                <h4 className='pt-2'>UNETI PODACI</h4>
              </div>
            </Col>
          </Row>
        </Col>

        <Col
          xs={12}
          sm={12}
          md={12}
          lg={10}
          xl={10}
          className='pt-3 Step6__orderInfo'>
          <Row>
            <Col
              lg={10}
              xl={10}
              md={10}
              sm={10}
              xs={10}
              className='Step6__orderInfo__item'>
              {userData.step5.name}
            </Col>
            <Col
              lg={10}
              xl={10}
              md={10}
              sm={10}
              xs={10}
              className='Step6__orderInfo__item mt-3'>
              {userData.step5.phoneNumber}
            </Col>
            <Col
              lg={10}
              xl={10}
              md={10}
              sm={10}
              xs={10}
              className='Step6__orderInfo__item mt-3'>
              {userData.step3.city}
            </Col>
            <Col
              lg={10}
              xl={10}
              md={10}
              sm={10}
              xs={10}
              className='Step6__orderInfo__item mt-5'>
              {userData.step3.address}
            </Col>
            {userData.step3.appartment_number && (
              <Col
                lg={10}
                xl={10}
                md={10}
                sm={10}
                xs={10}
                className='mt-3 px-0'
                style={{ display: "flex", alignItems: "center" }}>
                <Col lg={1} xl={1} md={1} sm={2} xs={2} style={styledValues}>
                  {userData.step3.appartment_number}
                </Col>
                <Col
                  lg={9}
                  xl={9}
                  md={9}
                  sm={8}
                  xs={8}
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}>
                  {" "}
                  Broj stana
                </Col>
              </Col>
            )}

            {userData.step3.floor && (
              <Col
                lg={10}
                xl={10}
                md={10}
                sm={10}
                xs={10}
                className='mt-3 px-0'
                style={{ display: "flex", alignItems: "center" }}>
                <Col lg={1} xl={1} md={1} sm={2} xs={2} style={styledValues}>
                  {userData.step3.floor}
                </Col>
                <Col
                  lg={9}
                  xl={9}
                  md={9}
                  sm={8}
                  xs={8}
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}>
                  {" "}
                  Sprat
                </Col>
              </Col>
            )}

            <Col
              lg={10}
              xl={10}
              md={10}
              sm={10}
              xs={10}
              className='mt-3 px-0'
              style={{
                display: "flex",
                alignItems: "center",
              }}>
              <Col lg={1} xl={1} md={1} sm={2} xs={2} style={styledValues}>
                {userData.step3.has_elevator ? "da" : "ne"}
              </Col>
              <Col
                lg={9}
                xl={9}
                md={9}
                sm={8}
                xs={8}
                style={{
                  display: "flex",
                  alignItems: "center",
                }}>
                {" "}
                Lift
              </Col>
            </Col>
            <Col lg={10} xl={10} md={10} sm={10} xs={10} className='mt-3 px-0'>
              <Col
                xl={6}
                lg={6}
                md={7}
                sm={8}
                xs={8}
                className='Step6__orderInfo__item '>
                <p className='my-0'>
                  {userData.step4.date.replaceAll("-", ".")}
                </p>
                <p className='my-0 pl-3'>{userData.step4.time}h</p>
              </Col>
            </Col>
            <Col
              lg={10}
              xl={10}
              md={10}
              sm={10}
              xs={10}
              className='mt-3 px-0'
              style={{ display: "flex", justifyContent: "center" }}>
              <NextCategoryButton saveForm={sendOrder} />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default Step6;
