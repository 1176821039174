import React from "react";
import { Form } from "react-bootstrap";

const InputComp = ({
  type,
  name,
  label,
  value,
  handleInfo,
  error,
  placeHolder,
}) => {
  return (
    <Form.Group controlId='formBasicEmail'>
      <Form.Label className='float-left'>{label}</Form.Label>
      <Form.Control
        type={type}
        value={value ? value : ""}
        name={name}
        onChange={(e) => handleInfo(e)}
        style={error ? { border: "1px solid red" } : {}}
        placeholder={placeHolder && placeHolder}
      />

      <small id='emailHelp' className='form-text text-muted d-flex'>
        {error}
      </small>
    </Form.Group>
  );
};

export default InputComp;
