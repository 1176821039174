import React from "react";
import ReactDOM from "react-dom";
import thunk from "redux-thunk";
import { Provider } from "react-redux";
import { createStore, applyMiddleware, combineReducers } from "redux";
import stepReducer from "./store/reducers/step";
import locationReducer from "./store/reducers/location";
import formReducer from "./store/reducers/form";
import serviceReducer from "./store/reducers/services";
import freeSlotsReducer from "./store/reducers/freeSlots";
import App from "./App";
import "./index.css";

const allReducers = combineReducers({
  step: stepReducer,
  locations: locationReducer,
  userData: formReducer,
  services: serviceReducer,
  freeSlots: freeSlotsReducer,
});

const initialState = allReducers({}, {});

const rootReducer = (state, action) => {
  if (action.type === "REMOVE_USER_DATA") {
    state = initialState;
  }

  return allReducers(state, action);
};

const store = createStore(rootReducer, applyMiddleware(thunk));

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Provider>,

  document.getElementById("root")
);
