import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { findFormErrors } from "../../../validation/form-validation";
import {
  setUserFormData,
  clearAppoitmentsErrors,
} from "../../../store/actions/form";
import { setStep } from "../../../store/actions/step";
import InputComp from "../InputComp/InputComp";
import NextCategoryButton from "../NextButton/NextCategoryButton";
import { Row, Col, Form } from "react-bootstrap";
import "./Step5.scss";

const Step5 = () => {
  const prevUserData = useSelector((state) => state?.userData?.step5);
  const [errors, setErrors] = useState({});
  const [userOrder, setUserOrder] = useState(
    prevUserData ? prevUserData : { name: "", email: "", phoneNumber: "" }
  );
  const dispatch = useDispatch();

  const saveForm = (step) => {
    let errorsValidation = findFormErrors(userOrder);
    setErrors(errorsValidation);

    if (Object.keys(errorsValidation).length === 0) {
      dispatch(setUserFormData("step5", { ...userOrder }));
      dispatch(clearAppoitmentsErrors(null));
      dispatch(setStep(step + 1));
    }
  };

  //set other form informations
  const handleOtherInfo = (event) => {
    let input = userOrder;
    input[event.target.name] = event.target.value;

    setUserOrder((prevState) => {
      return { ...prevState, ...input };
    });
  };

  return (
    <div className='Step5'>
      <Row className='w-100 mx-0 Step5__container'>
        <div className='title_line'></div>
        <Col lg={12} xl={12} className='pt-2 step_title'>
          <p>UNESITE VASE PODATKE</p>
        </Col>

        <Col lg={12} xl={12} sm={12}>
          <Col
            sm={12}
            lg={12}
            xl={10}
            className='mx-auto'
            style={{ display: "flex", justifyContent: "center" }}>
            <Form className='pt-4'>
              <InputComp
                type='text'
                name='name'
                label='Vase ime:'
                error={errors.name}
                value={userOrder.name}
                handleInfo={handleOtherInfo}
              />
              <InputComp
                type='email'
                name='email'
                label='Email:'
                value={userOrder.email}
                handleInfo={handleOtherInfo}
                error={errors.email}
              />
              <InputComp
                type='text'
                name='phoneNumber'
                label='Broj telefona:'
                value={userOrder.phoneNumber}
                handleInfo={handleOtherInfo}
                error={errors.phoneNumber}
              />
              {/* <Form.Group
                className='mb-4'
                controlId='exampleForm.ControlTextarea1'>
                <Form.Label className='float-left'>Komentar:</Form.Label>
                <Form.Control
                  as='textarea'
                  name='comment'
                  value={userOrder.comment}
                  onChange={handleOtherInfo}
                  style={{ resize: "none" }}
                />
              </Form.Group> */}
              <NextCategoryButton saveForm={saveForm} />
            </Form>
          </Col>
        </Col>
      </Row>
    </div>
  );
};

export default Step5;
