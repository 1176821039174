import React, { useEffect, useState } from "react";
import { Modal, Image, Table } from "react-bootstrap";
import ModalLogo from "../../../assets/images/logo-normal-svg.png";
import "./ScheduledAppointments.scss";

const ScheduledAppointments = ({
  show,
  setIsModalShow,
  setNumberOfAppointments,
}) => {
  const [sheduledAppoitments, setSheduledAppoitments] = useState([]);

  useEffect(() => {
    let filteredAppoitments = filterAppointments();
    setNumberOfAppointments(filteredAppoitments?.length);
    setSheduledAppoitments([...filteredAppoitments]);

    const interval = setInterval(() => {
      let filteredAppoitments = filterAppointments();
      setNumberOfAppointments(filteredAppoitments?.length);
      setSheduledAppoitments([...filteredAppoitments]);
    }, 10000);

    return () => clearInterval(interval);
  }, []);

  const filterAppointments = () => {
    if (localStorage.getItem("scheduledAppointments")) {
      let convertAppoitments = JSON.parse(
        localStorage.getItem("scheduledAppointments")
      );

      let nowDate = new Date();
      let filteredAppoitments = convertAppoitments.filter(
        (appoitment) =>
          new Date(appoitment.date + " " + appoitment.time) > nowDate
      );

      return filteredAppoitments;
    } else {
      return [];
    }
  };

  const checkAppointments =
    sheduledAppoitments.length > 0 &&
    sheduledAppoitments.map((appoitment, index) => {
      return (
        <tr key={index + 1}>
          <td>{index + 1}</td>
          <td>{appoitment.date}</td>
          <td>{appoitment.time}</td>
          <td>{appoitment.location.city}</td>
          <td>
            <Image
              src={appoitment.service.image_url}
              style={{ height: "50px", width: "50px" }}
            />
          </td>
        </tr>
      );
    });

  return (
    <div className='ScheduledAppointments'>
      <Modal
        animation={false}
        size='lg'
        show={show}
        onHide={() => setIsModalShow(false)}
        aria-labelledby='example-modal-sizes-title-lg'
        centered>
        <Modal.Header closeButton>
          <Image src={ModalLogo} />

          <Modal.Title id='example-modal-sizes-title-lg'>
            <p className='my-auto mx-auto text-center'>Zakazani termini</p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table
            striped
            bordered
            hover
            size='sm'
            style={{ textAlign: "center", verticalAlign: "middle" }}>
            <thead>
              <tr>
                <th></th>
                <th>Datum</th>
                <th>Vreme</th>
                <th>Grad</th>
                <th>Usluga</th>
              </tr>
            </thead>
            <tbody>{checkAppointments}</tbody>
          </Table>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ScheduledAppointments;
