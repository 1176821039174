import React from "react";
import { useSelector } from "react-redux";
import OrderPlaceHolder from "./OrderPlaceholder/OrderPlaceHolder";
import Header from "../Header/Header";
import Step1 from "./Step1/Step1";
import Step2 from "./Step2/Step2";
import Step3 from "./Step3/Step3";
import Step4 from "./Step4/Step4";
import Step5 from "./Step5/Step5";
import Step6 from "./Step6/Step6";
import Step7 from "./Step7/Step7";
import Stepper from "./Stepper/Stepper";
import { Row, Col } from "react-bootstrap";
import "./OrderForm.scss";

const OrderForm = () => {
  const step = useSelector((state) => state.step);

  const returnStep = () => {
    if (step === 1) {
      return <Step1 />;
    } else if (step === 2) {
      return <Step2 />;
    } else if (step === 3) {
      return <Step3 />;
    } else if (step === 4) {
      return <Step4 />;
    } else if (step === 5) {
      return <Step5 />;
    } else if (step === 6) {
      return <Step6 />;
    } else if (step === 7) {
      return <Step7 />;
    }
  };

  return (
    <Row className='OrderForm'>
      {step !== 7 && <Header />}

      <Col
        xl={step === 7 ? 12 : 6}
        lg={step === 7 ? 12 : 6}
        md={step === 7 ? 12 : 6}
        className='OrderForm__content'
        style={step === 6 ? { padding: "0" } : {}}>
        <div
          className='step_layer'
          style={{
            minHeight: step < 7 ? "90%" : "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}>
          {returnStep()}
        </div>
        <div
          style={{
            height: "10%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingBottom: "50px",
            // paddingTop: "30px",
            paddingTop: step === 1 ? "0px" : "30px",
          }}>
          {step !== 2 && (
            <Col
              sm={12}
              md={step === 7 ? 8 : 12}
              lg={step === 7 ? 6 : 10}
              xl={6}
              className='px-0'
              style={{ display: "flex", justifyContent: "center" }}>
              <Stepper />
            </Col>
          )}
        </div>
      </Col>
      {step !== 7 && (
        <Col xl={6} lg={6} md={6}>
          <OrderPlaceHolder />
        </Col>
      )}
    </Row>
  );
};

export default OrderForm;
