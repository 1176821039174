import React, { useState, useEffect, useRef } from "react";
import "./ChooseSlot.scss";
import { useSelector } from "react-redux";

import { HiArrowLeft, HiArrowRight } from "react-icons/hi";
import dayjs from "dayjs";

const BookCalendar = ({ userOrder, setUserOrder }) => {
  const [position, setPosition] = useState(0);
  // const [startDate, setStartDate] = useState("");
  // const [endDate, setEndDate] = useState("");
  const weekRef = useRef(null);
  const [currMonth, setCurrMonth] = useState("");
  const [mobileYprevious, setMobileYPrevious] = useState(null);
  // const [selectedDate, setSelectedDate] = useState({});

  const daysNames = [
    "Nedelja",
    "Ponedeljak",
    "Utorak",
    "Sreda",
    "Cetvrtak",
    "Petak",
    "Subota",
  ];

  const monthNames = [
    "Januar",
    "Februar",
    "Mart",
    "April",
    "Maj",
    "Jun",
    "Jul",
    "Avgust",
    "Septembar",
    "Oktobar",
    "Novembar",
    "Decembar",
  ];

  // const { freeSlots, widget } = useSelector((state) => state.widget);
  const { freeSlots } = useSelector((state) => state.freeSlots);

  useEffect(() => {
    const scrollOverride = (e) => {
      let y = 0;
      const slots = document.querySelectorAll(".slots");
      if (e.type === "touchmove") {
        y = e.touches[0].pageY;
        setMobileYPrevious(y);
        // console.log("Previous", mobileYprevious);
        // console.log("Current", y);
        if (mobileYprevious) {
          y = mobileYprevious - y;
        } else y = 0;
        // console.log("Y", y);

        if (y < 50 || y > 50) {
        } else y = 0;

        if (y) {
          slots.forEach((slot) => {
            let scrolled = slot.scrollTop;
            slot.scroll(0, scrolled + y);
          });
        }
      } else {
        y = e.deltaY;

        slots.forEach((slot) => {
          let scrolled = slot.scrollTop;
          slot.scroll(0, scrolled + y / 5);
        });
      }
      e.preventDefault();
    };

    const touchEndCalled = (e) => {
      // console.log("TOUCHEND");
      setMobileYPrevious(null);
    };

    if (weekRef.current) {
      weekRef.current.addEventListener("wheel", scrollOverride, {
        passive: false,
      });

      weekRef.current.addEventListener("touchmove", scrollOverride);
      weekRef.current.addEventListener("touchend", touchEndCalled);
    }
  }, [mobileYprevious]);

  const dayInWeek = (date, values, key) => {
    const dayName = daysNames[dayjs(date).day()];

    return (
      <div className='Day' key={key}>
        <div className='title'>
          <span>{dayName}</span>
          <p>{dayjs(date).format("DD")}</p>
        </div>
        <div className='slots'>
          {values &&
            values.map(
              (time, index) =>
                time && (
                  <button
                    key={index}
                    onClick={() => {
                      setUserOrder({
                        date: date,
                        time: time.time,
                        index: index,
                      });
                    }}
                    className={`available mx-auto ${
                      userOrder.date === date &&
                      userOrder.index === index &&
                      " selectedDate"
                    }`}
                    style={
                      time.available ? { backgroundColor: "#f2f2f2" } : null
                    }
                    disabled={!time.available && true}
                    // style={{
                    //   color: widget.company.widget_settings.main_color
                    //     ? widget.company.widget_settings.main_color
                    //     : "#1A73E8",
                    // }}
                  >
                    {time.time.substring(0, 5)}
                  </button>
                )
            )}
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (freeSlots) {
      const start = position * 5;

      // const len = Object.keys(freeSlots).length;
      // const end = position * 5 + 5 > len - 1 ? len - 1 : position * 5 + 5;
      // setStartDate(dayjs(Object.keys(freeSlots)[start]).format("DD"));
      // setEndDate(dayjs(Object.keys(freeSlots)[end]).format("DD"));
      // console.log(dayjs(Object.keys(freeSlots)[start]).format("MMMM"));

      let numberOfMonth = Object.keys(freeSlots)[start];
      let translateMonth = monthNames[dayjs(numberOfMonth).get("month")];

      setCurrMonth(
        `${translateMonth} ${dayjs(Object.keys(freeSlots)[start]).format(
          "YYYY"
        )}`
      );
    }
    // eslint-disable-next-line
  }, [position, currMonth, freeSlots]);

  return (
    <div className='BookCalendar'>
      <div className='header'>
        <h2>{currMonth}</h2>
      </div>

      <div className='week' ref={weekRef}>
        {freeSlots &&
          Object.keys(freeSlots)
            .slice(position > 0 ? position * 5 : position * 5, position * 5 + 5)
            .map((day, key) => {
              return dayInWeek(day, freeSlots[day], key);
            })}
      </div>
      <div
        className='arrow left'
        onClick={() => position !== 0 && setPosition((p) => p - 1)}>
        <HiArrowLeft />
      </div>
      <div
        className='arrow right'
        onClick={() =>
          position <= Object.keys(freeSlots).length / 5 - 1 &&
          setPosition((p) => p + 1)
        }>
        <HiArrowRight />
      </div>
    </div>
  );
};

export default BookCalendar;
