import axios from "../../Utility/axios";
import { setStep } from "../actions/step";
import { setAppoitmentErrors, setAppoitmentSuccess } from "../actions/form";

export const createAppointment = (appointment) => {
  return (dispatch) => {
    axios
      .post("/create-appointment", appointment)
      .then((res) => {
        if (res.status === 200) {
          let allAppointments = [];
          allAppointments.push(res.data.data);
          let oldAppointments = JSON.parse(
            localStorage.getItem("scheduledAppointments")
          );

          if (oldAppointments !== null) {
            localStorage.setItem(
              "scheduledAppointments",
              JSON.stringify([...oldAppointments, ...allAppointments])
            );
          } else {
            localStorage.setItem(
              "scheduledAppointments",
              JSON.stringify([...allAppointments])
            );
          }

          dispatch(setAppoitmentSuccess(res.data.message));
          dispatch(setStep(7));
        }
      })
      .catch((error) => {
        if (error.response.status === 403) {
          dispatch(
            setAppoitmentErrors({ busyTerm: [error.response.data.message] })
          );
        } else {
          dispatch(setAppoitmentErrors(error.response.data.errors));
        }
      });
  };
};
