import React from "react";
import BackButton from "../OrderForm/BackButton/BackButton";
import { Image } from "react-bootstrap";
import LogoHeader from "../../assets/images/logo-normal-svg.png";
import "./Header.scss";

const Header = () => {
  return (
    <div className='Header'>
      <div className='logo-wraper'>
        <Image className='float-left pl-1' src={LogoHeader} />
      </div>

      <BackButton />
    </div>
  );
};

export default Header;
