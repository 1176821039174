import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { detectionLocation } from "../../../store/actions/location";
import LocationList from "../LocationsList/LocationsList";
import Swal from "sweetalert2";
import "./LocationPicker.scss";

const LocationPicker = ({ saveLocation }) => {
  const locations = useSelector((state) => state.locations.detectLocation);
  const [isOpened, setIsOpened] = useState(false);
  const [isAddressShown, setIsAdressShown] = useState(true);

  const dispatch = useDispatch();

  useEffect(() => {
    if (isOpened) {
      position();
    }
  }, [isOpened]);

  const position = async () => {
    await navigator.geolocation.getCurrentPosition(
      (position) => {
        let finallLocation = {
          lat: position.coords.latitude,
          lon: position.coords.longitude,
        };
        dispatch(detectionLocation(finallLocation));
      },
      (err) => {
        if (err.code === 1 && err.message === "User denied Geolocation")
          Swal.fire({
            position: "top-center",
            icon: "warning",
            title: "Dozvolite pristup lokaciji",
            showConfirmButton: false,
            timer: 3000,
          });
      },
      { enableHighAccuracy: true }
    );
  };

  return (
    <div className='LocationPicker mb-2'>
      {isOpened ? (
        <div
          className='LocationPicker__opened'
          onClick={() => {
            if (isOpened) {
              setIsOpened(false);
            }
          }}>
          <i className='fas fa-minus'></i>
          <span className='ml-2'>Sakrijte lokaciju</span>
          <hr />
        </div>
      ) : (
        <div
          className='LocationPicker__closed'
          onClick={() => {
            if (!isOpened) {
              setIsOpened(true);
              setIsAdressShown(true);
            }
          }}>
          <i className='fas fa-plus' style={{ color: "#98e733" }}></i>
          <span className='ml-2'>Otkrivanje lokacije</span>
          <hr />
        </div>
      )}

      {isOpened && (
        <LocationList
          isAddressShown={isAddressShown}
          locations={locations}
          saveLocation={saveLocation}
          setIsAdressShown={setIsAdressShown}
          setIsOpened={setIsOpened}
        />
      )}
    </div>
  );
};

export default LocationPicker;
