import * as actionTypes from "./actionTypes";
import axios from "../../Utility/axios";

export const saveServices = (payload) => {
  return {
    type: actionTypes.SAVE_SERVICES,
    payload: payload,
  };
};

export const saveSelectedServices = (payload) => {
  return {
    type: actionTypes.SAVE_SELECTED_SERVICES,
    payload: payload,
  };
};

export const getServices = () => {
  return (dispatch) => {
    axios
      .get("/services")
      .then((res) => {
        dispatch(saveServices(res.data.data));
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const getServicesResponse = (serviceId) => {
  return (dispatch) => {
    axios
      .get(`/services/${serviceId}`)
      .then((res) => {
        dispatch(saveSelectedServices(res.data.data));
      })
      .catch((error) => {
        console.log(error);
      });
  };
};
