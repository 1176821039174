import React from "react";
import { Container } from "react-bootstrap";
import OrderForm from "./components/OrderForm/OrderForm";
import Footer from "./components/Footer/Footer";
import "./App.css";

const App = () => {
  return (
    <div className='App'>
      <Container fluid style={{ height: "95vh" }} className='mx-0 px-0'>
        <OrderForm />
      </Container>
      <Footer />
    </div>
  );
};

export default App;
