import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import NextCategoryButton from "../NextButton/NextCategoryButton";
import { setUserFormData } from "../../../store/actions/form";
import { setStep } from "../../../store/actions/step";
import { findFormErrors } from "../../../validation/form-validation";
import { Image, Form, Col, Row } from "react-bootstrap";

import "./Step2.scss";

const Step2 = () => {
  const prevUserData = useSelector((state) => state?.userData?.step2);
  const serviceResponse = useSelector(
    (state) => state?.services?.selectedServices
  );

  const dispatch = useDispatch();

  const [userOrder, setUserOrder] = useState(
    prevUserData
      ? prevUserData
      : {
          comment: "",
        }
  );

  const handleOtherInfo = (event) => {
    let input = userOrder;
    input[event.target.name] = event.target.value;

    setUserOrder((prevState) => {
      return { ...prevState, ...input };
    });
  };

  const saveForm = (step) => {
    let errorsValidation = findFormErrors(userOrder);

    if (Object.keys(errorsValidation).length === 0) {
      dispatch(setUserFormData("step2", { ...userOrder }));
      dispatch(setStep(step + 1));
    }
  };

  return (
    <div className='Step2'>
      <Row>
        <Col xl={6} lg={8} md={10} sm={11} xs={11}>
          <Image src={serviceResponse?.image_url} style={{ width: "60%" }} />
          <div className='comments'>
            <p>{serviceResponse?.description}</p>
          </div>
          <Form.Group className='mb-2' controlId='exampleForm.ControlTextarea1'>
            <Form.Control
              as='textarea'
              placeholder={serviceResponse?.comment_placeholder}
              name='comment'
              value={userOrder.comment}
              onChange={handleOtherInfo}
              style={{ resize: "none" }}
            />
          </Form.Group>

          {/* <Col
        sm={12}
        md={12}
        lg={10}
        xl={6}
        className='mx-auto pt-5'
        style={{ display: "flex", justifyContent: "center" }}> */}
          <NextCategoryButton saveForm={saveForm} />
          {/* </Col> */}
        </Col>
      </Row>
    </div>
  );
};

export default Step2;
