import React from "react";
import { useSelector } from "react-redux";
import "./Stepper.scss";

const Stepper = () => {
  const step = useSelector((state) => state.step);

  return (
    <div className='Stepper'>
      <div className='container'>
        <ul className='progressbar'>
          <li className={`${step >= 1 && " active"}`} />
          {/* <li className={`${step >= 2 && " active"}`} /> */}
          <li className={`${step >= 3 && " active"}`} />
          <li className={`${step >= 4 && " active"}`} />
          <li className={`${step >= 5 && " active"}`} />

          {step <= 6 ? (
            <li className={`${step === 6 && " active additionall"}`} />
          ) : (
            <li className={`${step === 7 && " active"}`} />
          )}
        </ul>
      </div>
    </div>
  );
};

export default Stepper;
